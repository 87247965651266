(function (scope) {
    'use strict';

    // obtain cookieconsent plugin

    var cc = initCookieConsent();

    if (typeof scope.cookieConsent === 'undefined') {
        scope.cookieConsent = cc;
    }

    // run plugin with config object
    var autorun = function autorun() {
        cc.run({
            // cc_autorun : true, 								// show as soon as possible (without the need to manually call CookieConsent.show() )
            // cc_delay : 0,								    // specify initial delay after website has loaded
            cc_enable_verbose : false,                         // if enabled, prints all info/error msgs (not available on dist version)
            cc_current_lang: 'gl',
            // cc_policy_url : null,                           // specify your own dedicated cookie policy page url
            // cc_auto_language : true,						   // if enabled, overrides cc_current_lang
            cc_cookie_expiration: 182,
            // //cc_autoclear_cookies : true,
            // //cc_autoload_css : true,
            cc_theme_css: "/assets/staticCss/cookieconsent.css",
            // cc_accept_callback : function(cookies){
            //Example: if functionality cookies are enabled do something ...
            // window.location.reload();
            // },
            //
            cc_languages: [{
                lang: 'gl',
                modal: {
                    cc_title: "Política de Cookies",
                    cc_more_text: "Configurar cookies",
                    cc_accept_text: "Aceptar",
                    // cc_decline_text: "RECHAZAR",
                    cc_description: 'Usamos cookies propias y de terceros para fines analíticos y así poder conocer los hábitos de navegación ' +
                        '(por ejemplo, páginas visitadas). Puedes aceptar todas las cookies pulsando el botón "Aceptar" o configurarlas y rechazar su uso ' +
                        'desde el botón "configurar cookies"<br/> Más información en la sección <a ui-sref="cookies">Cookies</a>.'
                },
                policy: {
                    ccp_title: "Política de Cookies",
                    // ccb_table_headers is REQUIRED if any ccb_cookies_table is used
                    ccp_blocks: [{
                        ccb_title: "Cookies necesarias",
                        ccb_description: '<p>Estas cookies son necesarias para el correcto funcionamiento del sitio web. Sin ellas, el sitio podría no funcionar: <p/>' +
                            '<li>io: Cookie de sesión.</li>' +
                            '<li>cc_cookie: Cookie asociada a la aceptación de las cookies.</li>',
                        ccb_switch: {
                            value: 'necessary_cookies',
                            enabled: true,
                            readonly: true
                        }
                    }, {
                        ccb_title: "Cookies funcionais de terceiros",
                        ccb_description: '<p>Se emplean cookies de terceros asociadas a funcionalidades sociales (ver política de cookies). ' +
                            'De rechazarlas, no se podrán visualizar en la web las publicaciones del perfil de la AS-PG en las redes sociales. </p>' +
                            '<li>Facebook: Cookies vinculadas al componente <a href="https://developers.facebook.com/docs/plugins/page-plugin/" target="_blank">page-plugin</a>.</li>' +
                            '<li>Twitter: Cookies vinculadas al componente <a href="https://developer.twitter.com/en/docs/twitter-for-websites/timelines/overview" target="_blank">Embedded Timelines</a>.</li>',
                        ccb_switch: {
                            value: 'third_party_cookies',
                            enabled: false,
                            readonly: false
                        }
                    }, {
                        ccb_title: "Cookies analiticas",
                        ccb_description: '<p>Se emplean las cookies de <a href="https://analytics.google.com" target="_blank">Google Analytics</a> para darle una experiencia personalizada en el sitio web.</p>',
                        ccb_switch: {
                            value: 'analisis_cookies',
                            enabled: false,
                            readonly: false
                        }
                    }],
                    ccp_save_text: "Guardar preferencias"
                }
            }]
        });
    };

    autorun();
})(window);
